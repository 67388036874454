/** @jsx jsx */
import { jsx } from 'theme-ui'
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'
import { Box, Grid, Heading } from './'

export const FooterClaim = React.forwardRef(({ text = '', ...props }, ref) => {
  const data = useStaticQuery(graphql`
    query FooterClaimQuery {
      ffvText: file(relativePath: { eq: "ffv-text.png" }) {
        childImageSharp {
          fluid(maxWidth: 1119) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      ffvVisual: file(relativePath: { eq: "ffv-visual.png" }) {
        childImageSharp {
          fluid(maxWidth: 726) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)
  const textScaleMobile = 1.2
  const visualTextRatio = 769 / 1119
  return (
    <Grid columns={1} sx={{ mt: 4, overflow: 'hidden' }} ref={ref} {...props}>
      <Box
        sx={{
          gridColumn: 1,
          gridRow: [2, null, 1],
          ml: `50%`,
          transform: `translateX(-50%)`,
          width: [`${textScaleMobile * 100}%`, null, `${(1119 / 1920) * 100}%`],
          alignSelf: `center`,
          overflow: 'hidden',
        }}
      >
        <Image fluid={data.ffvText.childImageSharp.fluid} />
      </Box>
      <Box
        sx={{
          gridColumn: 1,
          gridRow: [2, null, 1],
          ml: `50%`,
          transform: `translateX(-50%)`,
          width: [
            `${visualTextRatio * textScaleMobile * 100}%`,
            null,
            `${(769 / 1920) * 100}%`,
          ],
          mixBlendMode: [null, null, `multiply`],
        }}
      >
        <Image fluid={data.ffvVisual.childImageSharp.fluid} />
      </Box>
      <Box
        sx={{
          bg: 'bubblegumPink',
          height: ['100%', null, '55%'],
          alignSelf: 'end',
          gridColumn: 1,
          gridRow: ['1 / span 2', null, 1],
        }}
      ></Box>
      <Heading
        as="div"
        variant="heading4"
        sx={{
          alignSelf: 'end',
          maxWidth: '990px',
          mx: 'auto',
          mb: `${(70 / 1119) * 100}%`,
          pt: [4, null, null],
          textAlign: 'center',
          width: '80%',
          gridColumn: 1,
          gridRow: 1,
        }}
      >
        {text}
      </Heading>
    </Grid>
  )
})
