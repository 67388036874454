import React from 'react'
import { responsivePx } from '../utils'
import { AspectRatio, Box, Button, Flex, Grid, Heading, Richtext } from './'

const what_we_do = 'what_we_do'
const why_we_do_it = 'why_we_do_it'
const for_a_better_world = 'for_a_better_world'

const backgrounds = {
  [what_we_do]: '#65cb99',
  [why_we_do_it]: '#e0b91b',
  [for_a_better_world]: 'linear-gradient(to bottom, #921cc6, #fddf3a)',
}

const WhatWeDo = ({
  headlineColor,
  headlineLines,
  copy,
  buttonLabel,
  buttonTarget,
  image,
}) => (
  <>
    <Flex
      sx={{
        justifyContent: ['center', null, null, 'flex-end'],
        position: 'relative',
      }}
    >
      <Heading
        as="h2"
        variant="heading2"
        sx={{ position: 'relative', zIndex: 1 }}
      >
        <Box
          sx={{
            mt: `${(-14 / 480) * 100}%`,
            transform: 'translateX(-45%)',
            width: `${(547 / 480) * 100}%`,
          }}
        >
          <AspectRatio ratio={image.width / image.height}>
            <img src={image.url} alt="" />
          </AspectRatio>
        </Box>
        <Box
          as="span"
          sx={{
            color: `${headlineColor}`,
            position: 'relative',
            zIndex: 1,
            display: 'block',
            mt: `${(-250 / 480) * 100}%`,
          }}
        >
          {headlineLines[0]}
        </Box>
        <Heading
          as="span"
          variant="heading3"
          sx={{ position: 'relative', zIndex: 1, display: 'block' }}
        >
          {headlineLines[1]}
        </Heading>
      </Heading>
    </Flex>
    <Richtext variant="textBody" sx={{ pt: [3, null, null, '145px'], pb: 2 }}>
      {copy}
      <Button to={buttonTarget} sx={{ mt: 4 }}>
        {buttonLabel}
      </Button>
    </Richtext>
  </>
)

const WhyWeDoIt = ({
  headlineColor,
  headlineLines,
  copy,
  buttonLabel,
  buttonTarget,
  image,
}) => (
  <>
    <Box>
      <AspectRatio
        ratio={image.width / image.height}
        sx={{
          mx: 'auto',
          width: responsivePx(300, 705),
          transform: ['translateX(22%)', null, null, 'translate(10%)'],
        }}
      >
        <img src={image.url} alt="" />
      </AspectRatio>
    </Box>
    <Box sx={{ py: [2, null, null, 5], position: 'relative' }}>
      <Heading
        as="h2"
        variant="heading6"
        sx={{ position: 'relative', zIndex: 1 }}
      >
        <Box
          as="span"
          sx={{
            color: `${headlineColor}`,
          }}
        >
          {headlineLines[0]}
        </Box>
        <Heading as="span" variant="heading7">
          {headlineLines[1]}
        </Heading>
      </Heading>
      <Richtext variant="textBody" sx={{ mt: [2, null, null, 4] }}>
        {copy}
      </Richtext>
      <Button to={buttonTarget} sx={{ mt: [2, null, null, 4] }}>
        {buttonLabel}
      </Button>
    </Box>
  </>
)

const ForABetterWorld = ({
  headlineColor,
  headlineLines,
  copy,
  buttonLabel,
  buttonTarget,
  image,
}) => (
  <>
    <Box
      sx={{
        pt: [2, null, null, 5],
        position: 'relative',
        gridColumn: [1, null, null, 2],
      }}
    >
      <AspectRatio
        ratio={image.width / image.height}
        sx={{
          mx: 'auto',
        }}
      >
        <img src={image.url} alt="" />
      </AspectRatio>
    </Box>
    <Box
      sx={{
        py: [2, null, null, 5],
        position: 'relative',
        gridColumn: [1, null, null, 1],
      }}
    >
      <Heading
        as="h2"
        variant="heading6"
        sx={{ position: 'relative', zIndex: 1 }}
      >
        <Box
          as="span"
          sx={{
            color: `${headlineColor}`,
          }}
        >
          {headlineLines[0]}
        </Box>
        <Heading as="span" variant="heading7">
          {headlineLines[1]}
        </Heading>
      </Heading>
      <Richtext variant="textBody" sx={{ mt: [2, null, null, 4] }}>
        {copy}
      </Richtext>
      <Button to={buttonTarget} sx={{ mt: [2, null, null, 4] }}>
        {buttonLabel}
      </Button>
    </Box>
  </>
)

const renderVariant = ({ variant, ...props }) => {
  if (variant === why_we_do_it) {
    return WhyWeDoIt({ ...props })
  } else if (variant === what_we_do) {
    return WhatWeDo({ ...props })
  } else if (variant === for_a_better_world) {
    return ForABetterWorld({ ...props })
  }
}

export const ImageText = React.forwardRef(
  ({ headline = '', variant = 'what_we_do', ...props }, ref) => {
    const headlineLines = headline ? headline.split('\n') : ['']
    return (
      <Box
        sx={{
          maxWidth: '100%',
          overflow: variant !== what_we_do ? 'hidden' : 'visible',
        }}
        {...props}
      >
        <Grid
          ref={ref}
          sx={{
            background: backgrounds[variant],
            px: ['5%', null, null, '15%'],
            gridTemplateColumns: ['1fr', null, null, '1fr 1fr'],
            gap: [1, null, null, 6],
            gridAutoFlow: variant === for_a_better_world ? 'column' : null,
            position: 'relative',
          }}
          {...props}
        >
          {renderVariant({
            variant,
            headlineLines,
            ...props,
          })}
        </Grid>
      </Box>
    )
  }
)
