import React from 'react'
import { Box, Heading, Grid } from '.'

export const SlicePeople = React.forwardRef(
  ({ headline = '', children, ...props }, ref) => {
    const headlineLines = headline ? headline.split('\n') : ['']
    return (
      <Box {...props}>
        <Box sx={{ position: 'relative' }}>
          <svg style={{ display: 'block' }} viewBox="0 0 1920 222">
            <rect x="0" y="0" width="1920" height="74" fill="#f9e16c" />
            <rect x="0" y="74" width="1920" height="74" fill="#fae67f" />
            <rect x="0" y="148" width="1920" height="74" fill="#fbeda6" />
          </svg>
          <Box
            sx={{
              position: 'absolute',
              left: 'calc(50% - 55px)',
              top: '0',
              width: '110px',
              height: '100%',
              bg: '#f8de5c',
            }}
          ></Box>
        </Box>
        <Heading as="h2" variant="heading6" sx={{ mt: 4, textAlign: 'center' }}>
          <Box
            sx={{
              position: 'absolute',
              left: 'calc(50% - 55px)',
              top: (theme) => `${theme.space[4] * -1}px`,
              width: '110px',
              height: (theme) => `${theme.space[4] + 60}px`,
              bg: '#f8de5c',
              zIndex: '-1',
            }}
          ></Box>
          <Heading as="span" variant="heading6line" />
          <Box
            as="span"
            sx={{
              color: 'wisteria',
            }}
          >
            {headlineLines[0]}
          </Box>
          <Heading as="span" variant="heading7" sx={{ color: 'black' }}>
            {headlineLines[1]}
          </Heading>
        </Heading>
        <Grid columns={[1]} sx={{ mt: 3 }}>
          {children}
        </Grid>
      </Box>
    )
  }
)
