import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'
import { responsivePx } from '../utils'
import { AspectRatio, Box, Flex, Heading } from './'

export const FromBerlin = ({ headline = '', ...props }) => {
  const data = useStaticQuery(graphql`
    query FromBerlinQuery {
      berlin: file(relativePath: { eq: "berlin.png" }) {
        childImageSharp {
          fluid(maxWidth: 352) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      berlinBg: file(relativePath: { eq: "berlin-bg.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)
  return (
    <Box sx={{ mt: -1, pt: 1, bg: 'softGreen' }} {...props}>
      <AspectRatio ratio={1922 / 988} contentAware={true}>
        <Image
          fluid={data.berlinBg.childImageSharp.fluid}
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          }}
          objectFit="cover"
          objectPosition="50% 50%"
        />
        <Flex
          sx={{
            px: '10%',
            with: '100%',
            minHeight: '100%',
            alignItems: 'center',
          }}
        >
          <Heading
            variant="heading4"
            sx={{
              display: 'flex',
              flexFlow: 'column',
              alignItems: 'center',
              textAlign: 'center',
              py: 3,
            }}
          >
            <AspectRatio
              ratio={352 / 536}
              sx={{ width: responsivePx(260, 352), mb: '-10%' }}
            >
              <Image fluid={data.berlin.childImageSharp.fluid} />
            </AspectRatio>
            <Box as="p" sx={{ position: 'relative' }}>
              {headline}
            </Box>
          </Heading>
        </Flex>
      </AspectRatio>
    </Box>
  )
}
