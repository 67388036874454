import React from 'react'
import { Flex, Grid, Text, Styled } from './'
import { Link as GatsbyLink } from 'gatsby'
import { linkResolver } from 'gatsby-source-prismic-graphql'

import {
  TiSocialInstagram,
  TiSocialFacebook,
  TiSocialLinkedin,
  TiSocialYoutube,
  TiSocialVimeo,
  TiWorld,
} from 'react-icons/ti'

export const Footer = ({
  activeDocMeta,
  contactLabel = 'Contact',
  contactLink,
  imprintLabel = 'Imprint',
  imprintLink,
  privacyLabel = 'Privacy',
  privacyLink,
  cookiePolicyLabel,
  cookieSettingsLabel,
  languageLabel = 'Deutsch',
  instagramUrl,
  facebookUrl,
  youtubeUrl,
  vimeoUrl,
  linkedinUrl,
}) => {
  const switchLanguageLink = activeDocMeta.lang !== 'de-de' ? '/de' : '/en'

  function handleClickCookieSetting(e) {
    e.preventDefault()
    window.Metomic('ConsentManager:show')
  }

  return (
    <Grid
      as="footer"
      sx={{
        px: `5%`,
        py: 5,
        justifyItems: ['center', null, 'start'],
        gridTemplateColumns: ['auto', null, 'auto min-content'],
        gridTemplateRows: 'auto',
        rowGap: 2,
        gridTemplateAreas: [
          `
          "meta"
          "social"
          "legal"
          `,
          null,
          `
          "meta social"
          "legal legal"
          `,
        ],
        bg: '#333333',
        color: 'white',
      }}
    >
      <Text
        as="div"
        variant="textSmall"
        sx={{
          gridArea: 'meta',
          ml: -1,
          '> *': {
            mx: 1,
          },
        }}
      >
        <Styled.a href={contactLink}>{contactLabel}</Styled.a>
        <Styled.a
          as={GatsbyLink}
          to={linkResolver(imprintLink)}
          key={imprintLink.id}
        >
          {imprintLabel}
        </Styled.a>

        <Styled.a href={switchLanguageLink} style={{ display: 'inline-block' }}>
          <TiWorld
            style={{ position: 'relative', top: '3px', marginRight: '0.5em' }}
          />
          {languageLabel}
        </Styled.a>
      </Text>
      <Flex
        sx={{
          gridArea: 'social',
          fontSize: '24px',
          lineHeight: '24px',
          mr: '-12px',
          '> a': {
            display: 'block',
            color: 'white',
            textDecoration: 'none',
            p: '12px',
            height: '48px',
            overflow: 'hidden',
          },
        }}
      >
        {instagramUrl && (
          <a href={instagramUrl} target="_blank" rel="noreferrer">
            <TiSocialInstagram />
          </a>
        )}

        {facebookUrl && (
          <a href={facebookUrl} target="_blank" rel="noreferrer">
            <TiSocialFacebook />
          </a>
        )}

        {youtubeUrl && (
          <a href={youtubeUrl} target="_blank" rel="noreferrer">
            <TiSocialYoutube />
          </a>
        )}

        {vimeoUrl && (
          <a href={vimeoUrl} target="_blank" rel="noreferrer">
            <TiSocialVimeo />
          </a>
        )}

        {linkedinUrl && (
          <a href={linkedinUrl} target="_blank" rel="noreferrer">
            <TiSocialLinkedin />
          </a>
        )}
      </Flex>
      <Text
        as="div"
        variant="textTiny"
        sx={{
          gridArea: 'legal',
          textAlign: ['center', null, 'left'],
          ml: -1,
          '> *': {
            mt: 1,
            mx: 1,
            display: 'inline-block',
          },
        }}
      >
        <Styled.a
          href="https://www.iubenda.com/privacy-policy/50776397"
          key={privacyLink.id}
          className="iubenda-white iubenda-embed"
        >
          {privacyLabel}
        </Styled.a>
        <Styled.a
          href="https://www.iubenda.com/privacy-policy/50776397/cookie-policy"
          key={privacyLink.id}
          className="iubenda-white iubenda-embed"
        >
          {cookiePolicyLabel}
        </Styled.a>
        <Styled.a href="#" onClick={handleClickCookieSetting}>
          {cookieSettingsLabel}
        </Styled.a>
      </Text>
      <script
        dangerouslySetInnerHTML={{
          __html: `(function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);`,
        }}
      />
    </Grid>
  )
}
