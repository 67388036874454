import React from 'react'
import { Box } from '.'
import ReactPlayer from 'react-player'

export const YouTubePlaylist = (props) => {
  return (
    <Box
      {...props}
      sx={{
        mt: -1,
        bg: '#65cb99',
        position: 'relative',
        zIndex: 50,
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          height: 'min(26vw, 200px)',
          background: '#000',
          transform: 'translate(0, 50%) skew(0, -2deg)',
        }}
      />
      <Box
        sx={{
          position: 'relative',
          display: 'grid',
          overflow: 'hidden',
          maxHeight: '90vh',
          bg: 'black',
          zIndex: '10',
        }}
      >
        <svg
          viewBox="0 0 16 9"
          style={{ display: 'block', gridArea: '1 / 1 / 2 / 2' }}
        />
        <ReactPlayer
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
          }}
          width="100%"
          height="100%"
          url="https://www.youtube.com/watch?v=XjI8THBdIdA&list=PLCJfrma0YtIDdh4HPU5tysQmkbFNu7DCr"
          controls={true}
          playing={true}
          muted={true}
          volume={1}
        />
      </Box>
      <Box
        sx={{
          height: 'min(26vw, 200px)',
          background: '#000',
          transform: 'translate(0, -50%) skew(0, -2deg)',
        }}
      />
    </Box>
  )
}
