/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Global, css } from '@emotion/core'

export const GlobalStyles = () => (
  <Global
    styles={css`
      /* stylelint-disable no-missing-end-of-source-newline */
      @font-face {
        font-family: 'Avenir Next LT Pro';
        src: url('/fonts/subset-AvenirNextLTPro-Bold.woff2') format('woff2'),
          url('/fonts/subset-AvenirNextLTPro-Bold.woff') format('woff');
        font-weight: bold;
        font-style: normal;
        font-display: swap;
      }

      @font-face {
        font-family: 'Avenir Next LT Pro';
        src: url('/fonts/subset-AvenirNextLTPro-Medium.woff2') format('woff2'),
          url('/fonts/subset-AvenirNextLTPro-Medium.woff') format('woff');
        font-weight: 500;
        font-style: normal;
        font-display: swap;
      }

      @font-face {
        font-family: 'Avenir Next LT Pro';
        src: url('/fonts/subset-AvenirNextLTPro-Demi.woff2') format('woff2'),
          url('/fonts/subset-AvenirNextLTPro-Demi.woff') format('woff');
        font-weight: 600;
        font-style: normal;
        font-display: swap;
      }

      * {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }

      a {
        word-break: break-all;
      }

      p {
        margin: 0;
      }

      svg {
        display: inline-block;
      }

      img {
        display: block;
        width: 100%;
        height: auto;
      }
    `}
  />
)
