import React from 'react'
import { css } from '@emotion/core'
import { Box, Button, Heading } from './'

export const GradientText = ({
  headline = '',
  buttonLabel = '',
  buttonTarget = '',
  ...props
}) => {
  return (
    <Box sx={{ textAlign: 'center', px: '10%' }} {...props}>
      <Heading
        as="p"
        variant="heading5"
        sx={{
          mt: 5,
          background:
            'linear-gradient(to right, #b86bda 1%, #f9a2b8 24%, #fcde3a 47%, #65cb99 72%, #3db6c6 99%)',
          // TODO: object styles are not prefixed at the moment.
          // Will be fixed with stylis@4
          // Meanwhile using emotion's `css` property
          // https://github.com/emotion-js/emotion/pull/1817
          // backgroundClip: 'text',
          // textFillColor: 'transparent',
          boxDecorationBreak: 'clone',
        }}
        css={css`
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        `}
      >
        {headline}
      </Heading>
      <Button to={buttonTarget} sx={{ mt: 3 }}>
        {buttonLabel}
      </Button>
    </Box>
  )
}
