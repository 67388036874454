import React from 'react'
import { Box, AspectRatio, Text } from '.'

export const SlicePeopleItem = React.forwardRef(
  ({ name = '', jobTitle = '', image, ...props }, ref) => {
    return (
      <Box sx={{ textAlign: 'center' }}>
        <AspectRatio
          ratio={1}
          sx={{
            width: '100%',
            maxWidth: '200px',
            mx: 'auto',
            mb: 1,
            borderRadius: '50%',
            bg: '#fff000',
            '*': {
              borderRadius: 'inherit',
            },
          }}
        >
          {image && <img src={image.url} alt="" />}
        </AspectRatio>
        <Text as="div" variant="textLoud" sx={{ color: 'black' }}>
          {name}
        </Text>
        <Text variant="textSmall">{jobTitle}</Text>
      </Box>
    )
  }
)
