import React from 'react'
import { AspectRatio, Heading, Text } from '.'

export const SliceGridItem = React.forwardRef(
  ({ headline = '', copy = '', icon, ...props }, ref) => {
    return (
      <div>
        <AspectRatio
          ratio={icon.width / icon.height}
          sx={{ width: '62px', mx: 'auto' }}
        >
          <img src={icon.url} alt="" />
        </AspectRatio>
        <Heading as="h3" variant="heading8" sx={{ color: 'black' }}>
          {headline}
        </Heading>
        <Text variant="textSmall" sx={{ mt: 1 }}>
          {copy}
        </Text>
      </div>
    )
  }
)
