import React from 'react'
import { css } from '@emotion/core'
import { Box } from 'theme-ui'
import { Link as GatsbyLink } from 'gatsby'
import { isLink, isInternalLink, responsivePx } from '../utils'

export const Button = React.forwardRef(
  (
    { as, children, to, href, buttonType, rel, variant = 'default', ...props },
    ref
  ) => {
    const internal = isInternalLink(to)
    const navigates = isLink(to)

    // TODO: Handle mailto links etc.

    if (navigates) {
      if (internal) {
        as = GatsbyLink
        // TODO: Not working at the moment
        // if (activeClassName === undefined) {
        //   activeClassName = 'active'
        // }
      } else {
        if (href === undefined) {
          href = to
        }
        to = undefined
        if (as === undefined) {
          as = 'a'
        }
        if (rel === undefined) {
          rel = 'nofollow'
        }
      }
    } else {
      if (as === undefined) {
        as = 'button'
      }
      if (as === 'button' && buttonType === undefined) {
        buttonType = 'button'
      }
    }

    return (
      <Box
        ref={ref}
        as={as}
        to={to}
        href={href}
        type={buttonType}
        rel={rel}
        variant="default"
        data-text={children}
        {...props}
        __themeKey="links"
        __css={{
          position: 'relative',
          display: 'inline-flex',
          alignItems: 'flex-start',
          justifyContent: 'center',
          minWidth: 32,
          minHeight: 32,
          m: 0,
          p: ['1em 1em', null, '1em 2em'],
          overflow: 'visible',
          fontFamily: 'inherit',
          fontSize: responsivePx(18, 20),
          fontWeight: 'semibold',
          lineHeight: '100%',
          letterSpacing: '2px',
          color: 'inherit',
          textAlign: 'left',
          textDecoration: 'none',
          textTransform: 'uppercase',
          whiteSpace: 'normal',
          verticalAlign: 'middle',
          touchAction: 'manipulation',
          cursor: 'pointer',
          userSelect: 'none',
          backgroundColor: 'transparent',
          borderStyle: 'solid',
          borderWidth: 3,
          borderColor: 'black',
          borderRadius: 0,
          '::after': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background:
              'linear-gradient(to right, #b86bda 1%, #f9a2b8 24%, #fcde3a 47%, #65cb99 72%, #3db6c6 99%)',
            transform: 'scaleX(0)',
            transformOrigin: '0% 50%',
            transition: 'transform 0.15s ease',
          },
          '&:hover::after': {
            transform: 'scaleX(1)',
          },
          '> span': {
            position: 'relative',
            zIndex: 1,
            pt: '0.2em',
            transition: 'transform 0.15s ease',
          },
          '&:hover > span': {
            transform: 'scale(1.1)',
          },
          variant: `buttons.${variant}`,
        }}
      >
        <span>{children}</span>
      </Box>
    )
  }
)
