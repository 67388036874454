import React from 'react'
import { Box } from 'theme-ui'

export const AspectRatio = React.forwardRef(
  (
    { ratio = 4 / 3, as = 'span', contentAware = false, children, ...props },
    ref
  ) => (
    <Box
      {...props}
      as={as}
      ref={ref}
      __css={{
        display: () => (contentAware ? 'flex' : 'block'),
        position: 'relative',
        width: '100%',
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: 0,
          paddingBottom: 100 / ratio + '%',
        }}
      />
      <Box
        __css={
          contentAware
            ? { width: '100%', marginLeft: '-100%' }
            : { position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }
        }
      >
        {children}
      </Box>
    </Box>
  )
)
