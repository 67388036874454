import React from 'react'
import { Box, Button, Flex, Heading } from '.'

export const SliceGrid = React.forwardRef(
  (
    { headline = '', buttonLabel = '', buttonTarget, children, ...props },
    ref
  ) => {
    const headlineLines = headline ? headline.split('\n') : ['']
    return (
      <Box sx={{ mt: 4, pt: 5, pb: 4, backgroundColor: '#3db6c6' }} {...props}>
        <Flex sx={{ justifyContent: 'center', mx: 'auto' }}>
          <Heading as="h2" variant="heading6" sx={{ textAlign: 'center' }}>
            <Heading as="span" variant="heading6line" />
            <Box
              as="span"
              sx={{
                color: 'bubblegumPink',
              }}
            >
              {headlineLines[0]}
            </Box>
            <Heading as="span" variant="heading7">
              {headlineLines[1]}
            </Heading>
          </Heading>
        </Flex>
        <Flex
          sx={{
            mt: 4,
            flexFlow: 'row wrap',
            '> *': {
              flex: ['0 1 100%', null, '0 1 50%'],
              mt: 4,
              px: '5%',
              textAlign: 'center',
            },
          }}
        >
          {children}
        </Flex>
        <Box sx={{ mt: 5, textAlign: 'center' }}>
          <Button to={buttonTarget}>{buttonLabel}</Button>
        </Box>
        {/* <Box sx={{ mt: 5 }}>
        <Text as="p" variant="heading9">
          Die Mudder aller Videoagenturen
        </Text>
      </Box> */}
      </Box>
    )
  }
)
