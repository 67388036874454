import React from 'react'
import { BaseStyles, Text } from 'theme-ui'

export const Richtext = React.forwardRef(({ children, ...props }, ref) => {
  return (
    <Text
      ref={ref}
      {...props}
      __css={{
        'p + p': {
          mt: 2,
        },
      }}
    >
      <BaseStyles>{children}</BaseStyles>
    </Text>
  )
})
