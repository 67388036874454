import React from 'react'
import { Box } from 'theme-ui'

export const Icon = React.forwardRef(
  ({ variant = 'default', ...props }, ref) => (
    <Box
      ref={ref}
      as="span"
      {...props}
      __themeKey="icons"
      __css={{
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 24,
        height: 24,
        '& svg': {
          fill: 'currentColor',
        },
        variant: `icons.${variant}`,
      }}
    />
  )
)
