import React from 'react'
import { Box } from 'theme-ui'
import { Link as GatsbyLink } from 'gatsby'
import { Link as PrismicLink } from 'prismic-reactjs'
import { linkResolver } from 'gatsby-source-prismic-graphql'
import { isLink, isInternalLink } from '../utils'

// '&:before, &:after': {
//   display: `block`,
//   content: `attr(data-text)`,
//   position: `absolute`,
//   top: 0,
//   left: 0,
//   opacity: 0.8,
// },
// '&:after': {
//   color: `#f0f`,
//   zIndex: -2,
// },
// '&:before': {
//   color: `#0ff`,
//   zIndex: -1,
// },
// '&:hover': {
//   '&:before': {
//     animation: `glitch .3s cubic-bezier(.25, .46, .45, .94) both infinite`,
//   },
//   '&:after': {
//     animation: `glitch .3s cubic-bezier(.25, .46, .45, .94) reverse both infinite`,
//   },
// },
// '@keyframes glitch': {
//   '0%': {
//     transform: `translate(0)`,
//   },
//   '20%': {
//     transform: `translate(-1px, 1px)`,
//   },
//   '40%': {
//     transform: `translate(-1px, -1px)`,
//   },
//   '60%': {
//     transform: `translate(1px, 1px)`,
//   },
//   '80%': {
//     transform: `translate(1px, -1px)`,
//   },
//   to: {
//     transform: `translate(0)`,
//   },
// },

export const Link = React.forwardRef(
  (
    { as, children, to, href, buttonType, rel, variant = 'default', ...props },
    ref
  ) => {
    let internal = isInternalLink(to)
    let navigates = isLink(to)

    if (to.hasOwnProperty('_linkType')) {
      navigates = true
      if (to._linkType === 'Link.document') {
        href = PrismicLink.url(to, linkResolver)
        internal = true
      } else {
        href = PrismicLink.url(to)
        internal = false
      }
    }

    // TODO: Handle mailto links etc.

    if (navigates) {
      if (internal) {
        as = GatsbyLink
        // TODO: Not working at the moment
        // if (activeClassName === undefined) {
        //   activeClassName = 'active'
        // }
      } else {
        if (href === undefined) {
          href = to
        }
        to = undefined
        if (as === undefined) {
          as = 'a'
        }
        if (rel === undefined) {
          rel = 'noopener'
        }
      }
    } else {
      if (as === undefined) {
        as = 'button'
      }
      if (as === 'button' && buttonType === undefined) {
        buttonType = 'button'
      }
    }

    return (
      <Box
        ref={ref}
        as={as}
        to={to}
        href={href}
        type={buttonType}
        rel={rel}
        variant="default"
        data-text={children}
        {...props}
        __themeKey="links"
        __css={{
          position: 'relative',
          display: 'inline-flex',
          alignItems: 'flex-start',
          justifyContent: 'center',
          minWidth: 32,
          minHeight: 32,
          m: 0,
          p: '6px 0',
          overflow: 'visible',
          fontFamily: 'inherit',
          fontSize: 'inherit',
          fontWeight: 'bold',
          lineHeight: 3,
          color: 'inherit',
          textAlign: 'left',
          textDecoration: 'none',
          whiteSpace: 'normal',
          verticalAlign: 'middle',
          touchAction: 'manipulation',
          cursor: 'pointer',
          userSelect: 'none',
          backgroundColor: 'transparent',
          border: 0,
          borderRadius: 4,
          '&::before': {
            content: `'-->'`,
            mr: 2,
            flex: '0 0 auto',
          },
          // pass variant prop to sx
          variant: `buttons.${variant}`,
        }}
      >
        {children}
      </Box>
    )
  }
)
